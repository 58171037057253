import React, { useState, useEffect } from 'react';

const CarForm = () => {
  // State for each form field
  const [form, setForm] = useState({
    photos: '',
    base_msrp: '',
    msrp: '',
    discounted_msrp: '',
    discounted_percentage: '',
    lease_monthly: '',
    lease_term_length: '',
    lease_down: '',
    yearly_mileage: '',
    residual: '',
    tax: '',
    lease_charge: '',
    discounts: '',
    money_factor: '',
    interior_color: '',
    exterior_color: '',
    drivetrain: '',
    transmission: '',
    engine: '',
    fuel: '',
    city_mpg: '',
    highway_mpg: '',
    total_seats: '',
    total_doors: '',
    comfort_features: '',
    entertainment_features: '',
    safety_features: '',
    warranty: '',
    packages: '',
    fees: '',
    vehicle_type: '',
    year: '',
    sticker: ''
  });
  
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [trims, setTrims] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedTrim, setSelectedTrim] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState('');

  // API Functions
  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = () => {
    window.apiRequest('/car_info/brands', { method: 'POST', headers: { "Accept": "application/json", "Content-Type": "application/json" }, body: JSON.stringify({}) }, (result, status) => {
      if (status === 200) setBrands(result);
    });
  };

  const getModels = (brand) => {
    setSelectedBrand(brand);
    window.apiRequest('/car_info/models', { method: 'POST', headers: { "Accept": "application/json", "Content-Type": "application/json" }, body: JSON.stringify({ brand }) }, (result, status) => {
      if (status === 200) setModels(result);
    });
  };

  const getTrims = (brand, model) => {
    setSelectedModel(model);
    window.apiRequest('/car_info/trims', { method: 'POST', headers: { "Accept": "application/json", "Content-Type": "application/json" }, body: JSON.stringify({ brand, model }) }, (result, status) => {
      if (status === 200) setTrims(result);
    });
  };

  const submitCar = (e) => {
    e.preventDefault();
    const carData = { ...form, ...features, brand: selectedBrand, model: selectedModel, trim: selectedTrim };
    window.apiRequest('/car', { method: 'POST', headers: { "Accept": "application/json", "Authorization": "Bearer " + localStorage.getItem('car_access_token'), "Content-Type": "application/json" }, body: JSON.stringify(carData) }, (result, status) => {
      setSubmissionStatus(status === 200 ? 'Submission successful!' : 'Submission failed.');
    });
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, files } = e.target;
  
    if (name === 'photos') {
      const fileArray = Array.from(files);
      const base64Array = [];
  
      fileArray.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          base64Array.push(reader.result.split(',')[1]);
  
          // Once all files are converted, update the form state
          if (base64Array.length === fileArray.length) {
            setForm((prevForm) => ({ ...prevForm, photos: base64Array }));
          }
        };
        reader.onerror = (error) => {
          console.error('Error converting file to base64:', error);
        };
      });
    } else if (name === 'sticker') {
      const file = files[0];
      const reader = new FileReader();
      
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];
        console.log(base64Data)
        
        // Update the form state with the base64 encoded photo
        setForm((prevForm) => ({ ...prevForm, sticker: base64Data }));
      };
      
      reader.onerror = (error) => {
        console.error('Error converting file to base64:', error);
      }
    } else {
      setForm({ ...form, [name]: e.target.value });
    }
  };

  //////////////////////////////////

  const [features, setFeatures] = useState({
    discounts: [],
    comfort_features: [],
    entertainment_features: [],
    safety_features: [],
    warranty: [],
    packages: [],
    fees: []
  });

  const handleAddRow = (field, e) => {
    e.preventDefault();
    setFeatures((prevFeatures) => ({
      ...prevFeatures,
      [field]: [...prevFeatures[field], { name: '', price: '' }]
    }));
  };
  
  const handleRemoveRow = (field, index, e) => {
    e.preventDefault();
    setFeatures((prevFeatures) => ({
      ...prevFeatures,
      [field]: prevFeatures[field].filter((_, i) => i !== index)
    }));
  };

  const handleInputChange = (field, index, e) => {
    const { name, value } = e.target;
    const updatedRows = [...features[field]];
  
    // Convert price to a number if the field is 'price'
    updatedRows[index][name] = name === "price" ? parseFloat(value) : value;
  
    setFeatures((prevFeatures) => ({
      ...prevFeatures,
      [field]: updatedRows
    }));
  };

  const renderFeatureSection = (title, field) => (
    <div style={styles.section2}>
      <h3>{title}</h3>
      {features[field]?.map((item, index) => (
        <div key={index} style={styles.row2}>
          <input
            type="text"
            name="name"
            placeholder="Item Name"
            value={item.name}
            onChange={(e) => handleInputChange(field, index, e)}
            style={styles.input}
          />
          <input
            type="number"
            name="price"
            placeholder="Price"
            value={item.price}
            onChange={(e) => handleInputChange(field, index, e)}
            style={styles.input}
          />
          <button onClick={(e) => handleRemoveRow(field, index, e)} style={styles.removeButton2}>-</button>
        </div>
      ))}
      <button onClick={(e) => handleAddRow(field, e)} style={styles.addButton2}>+ Add Item</button>
    </div>
  );

  return (
    <div style={styles.container}>
      <form onSubmit={submitCar} style={styles.form}>
        <h2 style={styles.header}>Car Information Form</h2>

        <div style={styles.formGroup}>
          <label style={styles.label}>Photos</label>
          <input style={styles.input} type="file" name="photos" onChange={handleChange} multiple />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Sticker</label>
          <input style={styles.input} type="file" name="sticker" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Year</label>
          <input style={styles.input} type="number" name="year" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Base MSRP</label>
          <input style={styles.input} type="number" name="base_msrp" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>MSRP</label>
          <input style={styles.input} type="number" name="msrp" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Discounted MSRP</label>
          <input style={styles.input} type="number" name="discounted_msrp" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Discounted MSRP Percentage off (Discounted MSRP / MSRP)</label>
          <input style={styles.input} type="number" name="discounted_percentage" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Monthly Payment</label>
          <input style={styles.input} type="number" name="lease_monthly" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Term Length</label>
          <input style={styles.input} type="number" name="lease_term_length" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Due at Signing</label>
          <input style={styles.input} type="number" name="lease_down" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Yearly Mileage</label>
          <input style={styles.input} type="number" name="yearly_mileage" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Residual</label>
          <input style={styles.input} type="number" name="residual" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Tax</label>
          <input style={styles.input} type="number" name="tax" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Lease Charge - Total Amount</label>
          <input style={styles.input} type="number" name="lease_charge" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Money Factor</label>
          <input style={styles.input} type="number" name="money_factor" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Interior Color</label>
          <div style={{width: '10px', height: '10px', backgroundColor: form.interior_color}}></div>
          <input style={styles.input} type="color" name="interior_color" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Exterior Color</label>
          <div style={{width: '10px', height: '10px', backgroundColor: form.exterior_color}}></div>
          <input style={styles.input} type="color" name="exterior_color" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Drive Train</label>
          <select style={styles.input} name="drivetrain" onChange={handleChange}>
            <option value="">Select Drive Train</option>
            <option value="AWD">AWD</option>
            <option value="RWD">RWD</option>
            <option value="FWD">FWD</option>
            <option value="4WD">4WD</option>
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Transmission</label>
          <select style={styles.input} name="transmission" onChange={handleChange}>
            <option value="">Select Transmission</option>
            <option value="Automatic">Automatic</option>
            <option value="Manual">Manual</option>
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Engine</label>
          <input style={styles.input} type="text" name="engine" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Fuel</label>
          <select style={styles.input} name="fuel" onChange={handleChange}>
            <option value="">Select Fuel Type</option>
            <option value="Gas">Gas</option>
            <option value="Electric">Electric</option>
            <option value="Hybrid">Hybrid</option>
            <option value="Diesel">Diesel</option>
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>City MPG</label>
          <input style={styles.input} type="number" name="city_mpg" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Highway MPG</label>
          <input style={styles.input} type="number" name="highway_mpg" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>total_seats</label>
          <input style={styles.input} type="number" name="total_seats" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>total_doors</label>
          <input style={styles.input} type="number" name="total_doors" onChange={handleChange} />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Vehicle Type</label>
          <select style={styles.input} name="vehicle_type" onChange={handleChange}>
            <option value="">Select Vehicle Type</option>
            <option value="Sedan">Sedan</option>
            <option value="SUV">SUV</option>
            <option value="Truck">Truck</option>
            <option value="Coupe">Coupe</option>
            <option value="Convertible">Convertible</option>
          </select>
        </div>

        {renderFeatureSection('Discounts - ALL NUMBERS POSITIVE', 'discounts')}
        {renderFeatureSection('Comfort Features', 'comfort_features')}
        {renderFeatureSection('Entertainment Features', 'entertainment_features')}
        {renderFeatureSection('Safety Features', 'safety_features')}
        {renderFeatureSection('Warranty Features', 'warranty')}
        {renderFeatureSection('List of Packages and Price', 'packages')}
        {renderFeatureSection('List of Transactional Fees and Price - Dont include tax or Lease Charge', 'fees')}

        <div style={styles.formGroup}>
          <label style={styles.label}>Brand</label>
          <select style={styles.input} onChange={(e) => getModels(e.target.value)}>
            <option value="">Select Brand</option>
            {brands.map((brand, index) => (
              <option key={index} value={brand}>{brand}</option>
            ))}
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Model</label>
          <select style={styles.input} onChange={(e) => getTrims(selectedBrand, e.target.value)}>
            <option value="">Select Model</option>
            {models.map((model, index) => (
              <option key={index} value={model}>{model}</option>
            ))}
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Trim</label>
          <select style={styles.input} name="trim" onChange={(e) => setSelectedTrim(e.target.value)}>
            <option value="">Select Trim</option>
            {trims.map((trim, index) => (
              <option key={index} value={trim}>{trim}</option>
            ))}
          </select>
        </div>

        <button onClick={(e) => {e.preventDefault(); submitCar(e)}} style={styles.button}>Submit</button>
        
        {submissionStatus && <p style={styles.statusMessage}>{submissionStatus}</p>}
      </form>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '700px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
    textAlign: 'center',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px',
  },
  label: {
    fontSize: '14px',
    marginBottom: '5px',
    color: '#555',
  },
  input: {
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    outline: 'none',
    backgroundColor: '#fff',
    transition: 'border-color 0.3s',
  },
  button: {
    marginTop: '20px',
    padding: '12px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#007BFF',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
  statusMessage: {
    marginTop: '15px',
    textAlign: 'center',
    color: '#28a745',
    fontWeight: 'bold',
  },
  container2: {
    maxWidth: '700px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  section2: {
    marginBottom: '30px',
  },
  row2: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  input2: {
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    outline: 'none',
    width: 'calc(50% - 20px)',
    marginRight: '10px',
  },
  addButton2: {
    padding: '8px 12px',
    fontSize: '14px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '10px',
    transition: 'background-color 0.3s',
  },
  removeButton2: {
    padding: '8px',
    fontSize: '14px',
    backgroundColor: '#e74c3c',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};


export default CarForm;

import React from 'react';

import FullLogo from '../media/FullLogo.png'
import Instagram from '../media/instagram.png'
import Twitter from '../media/twitter.png'
import Tiktok from '../media/tiktok.png'
import Linkedin from '../media/linkedin.png'
import Reddit from '../media/reddit.png'

import { Link } from "react-router-dom";

import { motion } from 'framer-motion'

const Main = (props) => {

    return (
        <div style={{ display: 'flex', flexDirection: window.innerWidth < 800 ? 'column':'row', width: '100%', fontFamily: 'inter', color: window.colors.primary, ...props.style, width: '100%', width: window.innerWidth < 1500 ? (window.innerWidth*0.95):'1500px'}}>
            <div style={{display: 'flex', flexDirection: 'column', marginLeft: window.innerWidth < 800 && '20px', marginBottom: '20px'}}>
                <Link to='/'><img src={FullLogo} style={{height: '74px', width: '95px'}}></img></Link>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                    <Link to='https://www.instagram.com/autovitus_com/' target='_blank'><motion.div whileHover={{scale: 1.1}} className='center' style={{backgroundColor: window.colors.primary, borderRadius: '100px', height: '30px', width: '30px', marginRight: '10px', cursor: 'pointer'}}>
                        <img src={Instagram}></img>
                    </motion.div></Link>
                    <Link to='https://www.tiktok.com/@autovitus.com' target='_blank'><motion.div whileHover={{scale: 1.1}} className='center' style={{backgroundColor: window.colors.primary, borderRadius: '100px', height: '30px', width: '30px', marginRight: '10px', cursor: 'pointer'}}>
                        <img src={Tiktok}></img>
                    </motion.div></Link>
                    <Link to='https://x.com/autovitus' target='_blank'><motion.div whileHover={{scale: 1.1}} className='center' style={{backgroundColor: window.colors.primary, borderRadius: '100px', height: '30px', width: '30px', marginRight: '10px', cursor: 'pointer'}}>
                        <img src={Twitter}></img>
                    </motion.div></Link>
                    <Link to='https://www.linkedin.com/company/thinetics-software/?viewAsMember=true' target='_blank'><motion.div whileHover={{scale: 1.1}} className='center' style={{backgroundColor: window.colors.primary, borderRadius: '100px', height: '30px', width: '30px', marginRight: '10px', cursor: 'pointer'}}>
                        <img style={{width: '16px', height: '16px'}} src={Linkedin}></img>
                    </motion.div></Link>
                    <Link to='https://www.reddit.com/r/CarNegotiation/' target='_blank'><motion.div whileHover={{scale: 1.1}} className='center' style={{backgroundColor: window.colors.primary, borderRadius: '100px', height: '30px', width: '30px', marginRight: '10px', cursor: 'pointer'}}>
                        <img style={{width: '20px', height: '20px'}} src={Reddit}></img>
                    </motion.div></Link>
                </div>
                <Link to='/shop'><motion.button whileHover={window.bAnim} style={{border: 'none', backgroundColor: window.colors.primary, color: window.colors.white, fontFamily: 'inter', padding: '10px 40px 10px 40px', fontWeight: '500', borderRadius: '5px', marginTop: '20px', cursor: 'pointer'}}>Get Started</motion.button></Link>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', fontWeight: '300', marginLeft: window.innerWidth > 800 ? 'auto':'20px', marginRight: '50px', marginTop: window.innerWidth < 800 && '30px'}}>
                <span style={{fontSize: '16px', color: window.colors.primary, fontWeight: '400'}}>SUPPORT</span>
                <Link to='/contact-us' style={{marginTop: '20px', cursor: "pointer", textDecoration: 'none', color: window.colors.muted, fontVariationSettings: '"wght" 400'}}><motion.span whileHover={{color: window.colors.primary, fontWeight: '400', transition: {duration: 0.1}}}>Contact Us</motion.span></Link>
                <Link style={{marginTop: '15px', textDecoration: 'none', color: window.colors.muted, fontVariationSettings: '"wght" 400'}}><motion.span whileHover={{color: window.colors.primary, fontWeight: '400', transition: {duration: 0.1}}}>support@autovitus.com</motion.span></Link>
                <Link style={{marginTop: '15px', textDecoration: 'none', color: window.colors.muted, fontVariationSettings: '"wght" 400'}}><motion.span whileHover={{color: window.colors.primary, fontWeight: '400', transition: {duration: 0.1}}}>sales@autovitus.com</motion.span></Link>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', color: window.colors.muted, fontWeight: '300', marginRight: '50px', marginLeft: window.innerWidth < 800 && '20px', marginTop: window.innerWidth < 800 && '30px'}}>
                <span style={{fontSize: '16px', color: window.colors.primary, fontWeight: '400'}}>LEGAL</span>
                <Link to='/privacy-policy' style={{marginTop: '20px', cursor: "pointer", textDecoration: 'none', color: window.colors.muted, fontVariationSettings: '"wght" 400'}}><motion.span whileHover={{color: window.colors.primary, fontWeight: '400', transition: {duration: 0.1}}}>Privacy Policy</motion.span></Link>
                <Link to='/tos' style={{marginTop: '15px', cursor: "pointer", textDecoration: 'none', color: window.colors.muted, fontVariationSettings: '"wght" 400'}}><motion.span whileHover={{color: window.colors.primary, fontWeight: '400', transition: {duration: 0.1}}}>Terms of Service</motion.span></Link>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', color: window.colors.muted, fontWeight: '300', marginRight: '50px', marginLeft: window.innerWidth < 800 && '20px', marginTop: window.innerWidth < 800 && '30px'}}>
                <span style={{fontSize: '16px', color: window.colors.primary, fontWeight: '400'}}>TOOLS</span>
                <Link to='/deal-finder' style={{marginTop: '20px', cursor: "pointer", textDecoration: 'none', color: window.colors.muted, fontVariationSettings: '"wght" 400'}}><motion.span whileHover={{color: window.colors.primary, fontWeight: '400', transition: {duration: 0.1}}}>Deal Finder</motion.span></Link>
                <Link to='/share-lease' style={{marginTop: '15px', cursor: "pointer", textDecoration: 'none', color: window.colors.muted, fontVariationSettings: '"wght" 400'}}><motion.span whileHover={{color: window.colors.primary, fontWeight: '400', transition: {duration: 0.1}}}>Lease Beater</motion.span></Link>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', color: window.colors.muted, fontWeight: '300', marginLeft: window.innerWidth < 800 && '20px', marginTop: window.innerWidth < 800 && '30px'}}>
                <span style={{fontSize: '16px', color: window.colors.primary, fontWeight: '400'}}>NAV</span>
                <Link to='/shop' style={{marginTop: '20px', cursor: "pointer", textDecoration: 'none', color: window.colors.muted, fontVariationSettings: '"wght" 400'}}><motion.span whileHover={{color: window.colors.primary, fontWeight: '400', transition: {duration: 0.1}}}>Shop</motion.span></Link>
                <Link to='/the-process' style={{marginTop: '15px', cursor: "pointer", textDecoration: 'none', color: window.colors.muted, fontVariationSettings: '"wght" 400'}}><motion.span whileHover={{color: window.colors.primary, fontWeight: '400', transition: {duration: 0.1}}}>The Process</motion.span></Link>
                <Link to='/feedback' style={{marginTop: '15px', cursor: "pointer", textDecoration: 'none', color: window.colors.muted, fontVariationSettings: '"wght" 400'}}><motion.span whileHover={{color: window.colors.primary, fontWeight: '400', transition: {duration: 0.1}}}>Feedback</motion.span></Link>
            </div>
        </div>
    );
}

export default Main;

import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { Link, useNavigate } from "react-router-dom"

import Hero from '../elements/Hero'
import Navbar from '../elements/Navbar'
import FeaturedCars from '../elements/FeaturedCars'
import ShareLease from '../elements/ShareLease'
import TheProcess from '../elements/TheProcess'
import Perferences from '../elements/Preferences'
import EmailList from '../elements/EmailList'
import Footer from '../elements/Footer'

const Home = (props) => {

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    useEffect(() => {
        document.title = 'AutoVitus - Home'
    }, []);

    return (
        <div className='center' style={{width: '99.1vw', height: 'auto', backgroundColor: 'white', display: 'flex', flexDirection: 'column', overflowX: 'hidden'}}>
            <div style={{margin: 'auto', height: '100%', maxWidth: '1500px', paddingLeft: 'auto', paddingRight: 'auto'}}>
                <Navbar></Navbar>
                <Hero></Hero>
                <FeaturedCars style={{marginTop: '100px'}}></FeaturedCars>
                <ShareLease style={{marginTop: '150px'}}></ShareLease>
                <TheProcess style={{marginTop: '150px'}}></TheProcess>
                <Perferences style={{marginTop: '150px'}}></Perferences>
                <EmailList style={{marginTop: '150px'}}></EmailList>
                <Footer style={{marginTop: '100px'}}></Footer>
            </div>
        </div>
    )
}

export default Home
